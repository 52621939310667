/** @type {string} */
const NODE_ENV = process.env.NODE_ENV;
/** @type {string} */
const PRODUCTION = 'production';
/** @type {string} */
const DEVELOPMENT = 'development';

module.exports.IS_PRODUCTION_MODE = NODE_ENV === PRODUCTION;
module.exports.IS_DEVELOPMENT_MODE = NODE_ENV === DEVELOPMENT;
module.exports.NODE_ENV = NODE_ENV;
