const { IS_DEVELOPMENT_MODE } = require('./env/node');

/** @type {string} */
const PROTOCOL = 'https';
/** @type {string} */
const HOST = 'localhost';
/** @type {number} */
const PORT = 444;
/** @type {string} */
const LOCAL_CORE_URL = `${PROTOCOL}://${HOST}:${PORT}`;
/** @type {string} */
const LOCAL_CORE_VERSION = '@local';

module.exports.GET_CORE_CDN_URL = ENV => CORE_VERSION =>
    IS_DEVELOPMENT_MODE && CORE_VERSION === LOCAL_CORE_VERSION
        ? LOCAL_CORE_URL
        : `https://ubistatic2-a.akamaihd.net/sitegen/${ENV}/core/${CORE_VERSION}`;

module.exports.LOCAL_CORE_CONFIG = {
    HOST,
    PORT,
    PROTOCOL,
    LOCAL_CORE_URL
};

/* All core package global exports are located under "SiteGen" namespace */
module.exports.SITEGEN_NAMESPACE = {
    NAMESPACE: 'SiteGen',
    /* Remove when ~2.5.0 and lower are not supported */
    OLD_CORE_LOADER_NAME: 'Loader',
    CORE_LOADER_NAME: 'CoreLoader',
    /* Loader in embedded mode is different from standalone / preview loaders */
    EMBEDDED_LOADER_NAME: 'Loader',
    SITEGEN_CONFIG: 'SITEGEN_CONFIG',
    /* Used in BO, can be replaced entirely by local storage */
    SITEGEN_ADMIN_CONFIG: 'SITEGEN_ADMIN_CONFIG'
};

module.exports.LOCAL_CORE_VERSION = LOCAL_CORE_VERSION;
