// constants
const { SITEGEN_BUILD } = require('@sitegen/build/config/env/sitegen-build');
// types
import { IBootstrapAPI, ISiteGenNamespace } from '@sitegen/common/types';
import { IBootstrapConfig } from '@sitegen/common/constants/config';

import { SITEGEN_NAMESPACE } from '@sitegen/build/config/core';

const namespace: ISiteGenNamespace = window[SITEGEN_NAMESPACE.NAMESPACE] || {};
const bootstrapConfig: IBootstrapConfig | undefined = namespace.SITEGEN_CONFIG;

if (bootstrapConfig) {
    const Loader = namespace[SITEGEN_NAMESPACE.CORE_LOADER_NAME][SITEGEN_BUILD];
    Loader.bootstrapLoader().then((bootstrap: { default: Function }) => {
        bootstrap.default(bootstrapConfig).then(({ mount }: IBootstrapAPI) => {
            mount(document.getElementById('root') as HTMLElement);
        });
    });
} else {
    /* eslint-disable-next-line no-console */
    console.error('SITEGEN_CONFIG is not defined');
}

// need to disable typescript checking here,
// because @types/webpack & @types/webpack-env doesn't export module global interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const module: any;
if (module.hot) {
    module.hot.accept();
}
